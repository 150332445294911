import { Row, Col, Button } from "reactstrap";
import Wrapper from "../Wrapper";
import bannerImage from "./pc.png";

const img =
  "https://images.unsplash.com/photo-1517433456452-f9633a875f6f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1189&q=80";

function LearningPlatform() {
  return (
    <Wrapper backgroundImage={img}>
      <div className="content-banner">
        <Row className="align-items-center">
          <Col md={6} className="text-center">
            <h1>Technology careers for the 21st century</h1>
            <p>
              Wherever you may be- Upskill and re-skill with our career path programs, professional
              certifications and international internships.
            </p>
          </Col>
          <Col md={6}>
            <img src={bannerImage} alt="" className="img-fluid" />
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
}

export default LearningPlatform;
