function Wrapper(props) {
  const { children, backgroundImage = "" } = props;
  return (
    <div className="slide-item-container">
      <div className="slide-item bg-dark">
        <div className="background" style={{ backgroundImage: `url(${backgroundImage})` }} />
        <div className="contnet">{children}</div>
      </div>
    </div>
  );
}

export default Wrapper;
