import Banner from "./Banner";
import Intro from "./Intro";
import WhatWeDo from "./WhatWeDo";
import OurFulltackCapabilities from "./OurFulltackCapabilities";
import AppSlide from "./AppSlide";
import Testimonial from "./Testimonial";
import { theNetwork, hometary, ktmg } from "./array";
import { ContactForm } from "../../../components/forms";
import Footer from "./Footer";
import Wrapper from "./Wrapper";
import LearningPlatform from "./LearningPlatform";

const homeContent = [
  { content: <Banner /> },
  {
    content: (
      <Wrapper backgroundImage="https://images.unsplash.com/photo-1510070009289-b5bc34383727?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80">
        <div className="text-center text-white content-msg">
          <h2>We are designed for your success</h2>
          <p>
            From the get go, you will experience a stimulating learning environment that is both
            focused on making sure you get the industry level skills and a solid foundation for a
            sustainable career in software development.
          </p>
        </div>
      </Wrapper>
    ),
  },
  { content: <Intro /> },
  {
    content: (
      <Wrapper backgroundImage="https://images.unsplash.com/photo-1562184552-d33c64b991ba?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=634&q=80">
        <div className="text-center text-white content-msg p-4 c-view">
          <h3 style={{ fontWeight: 400, lineHeight: 1.5 }}>
            Being an average programmer is not where the opportunity lies. The industry faces a
            significant shortfall of talent, and the real opportunity lies in becoming a good
            software engineer . The world is changing fast , gone are the days when 4-5 year degrees
            were the only route to a successful career. Our graduates are recruited by the best
            irrespective of educational background.
          </h3>
        </div>
      </Wrapper>
    ),
  },
  { content: <LearningPlatform /> },
  // { content: <WhatWeDo /> },
  // { content: <OurFulltackCapabilities /> },
  // {
  //   content: (
  //     <Wrapper backgroundImage="https://images.unsplash.com/photo-1543053976-1f0d57a634ea?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80">
  //       <h2 className="client-success text-white">Client Success Stories</h2>
  //     </Wrapper>
  //   ),
  // },
  // { content: <AppSlide images={theNetwork} /> },
  // { content: <AppSlide images={hometary} /> },
  // { content: <AppSlide images={ktmg} /> },
  // {
  //   content: (
  //     <Wrapper backgroundImage="https://images.unsplash.com/photo-1502325966718-85a90488dc29?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80">
  //       <h2 className="text-center px-5 client-success text-white">What Our Clients Have To Say</h2>
  //     </Wrapper>
  //   ),
  // },
  // {
  //   content: <Testimonial />,
  // },
  // {
  //   content: (
  //     <Wrapper backgroundImage="https://images.unsplash.com/photo-1587560699334-cc4ff634909a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80">
  //       <div className="content-form">
  //         <div>
  //           <h3 className="text-primary mb-3">Let's Work together</h3>
  //           <ContactForm />
  //         </div>
  //       </div>
  //     </Wrapper>
  //   ),
  // },
  // {
  //   content: <Footer />,
  // },
];

export default homeContent;
