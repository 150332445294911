import { Row, Col, Button } from "reactstrap";
import Wrapper from "../Wrapper";
import bannerImage from "../../../../assets/images/banner/h-banner.svg";

// const img =
//   "https://images.unsplash.com/photo-1592146666475-bfc5662e1c5c?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=675&q=80";
const img = "";

function Banner() {
  return (
    <Wrapper backgroundImage={img}>
      <div className="content-banner">
        <Row className="align-items-center">
          <Col md={12} className="text-center">
            <h1>Technology careers for the 21st century</h1>
            <p>
              Wherever you may be- Upskill and re-skill with our career path programs, professional
              certifications and international internships.
            </p>
          </Col>
          <Col md={12}>
            <img src={bannerImage} alt="" className="img-fluid" />
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
}

export default Banner;
