import { Row, Col } from "reactstrap";
import Wrapper from "../Wrapper";
import crmIcon from "../../../../assets/images/icons/crm.webp";
import happyIcon from "../../../../assets/images/icons/happy.webp";
import seoIcon from "../../../../assets/images/icons/seo.webp";
import speedIcon from "../../../../assets/images/icons/speed.webp";

const img =
  "https://images.unsplash.com/photo-1510070112810-d4e9a46d9e91?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80";

function Intro() {
  const contentData = [
    {
      icon: seoIcon,
      title: "Experience based learning",
      value:
        "Learn by working on real projects, solve real challenges and build industry-scale products. After graduating, you walk out ready to join any tech team.",
    },
    {
      icon: speedIcon,
      title: "360 Fullstack experience",
      value:
        "360 degree not just covers the languages and frameworks but the best practice to develop, test and deploy software developed as part of the team.",
    },
    {
      icon: happyIcon,
      title: "1-to-1 mentorship based",
      value:
        "Never worry about keeping pace with the schedule. One on one mentorship allows fast paced personal attention to steer your career ahead.",
    },
    {
      icon: crmIcon,
      title: "Dedicated career services",
      value:
        "Our graduates jump the queue. Dedicated career services goes beyond interview preparations and position our graduates with companies looking to hire top tech talent.",
    },
  ];

  return (
    <Wrapper backgroundImage={img}>
      <div className="content-intro">
        <Row form>
          {contentData.map(({ icon, title, value }, index) => (
            <Col xs={6} key={index} className="mb-2">
              <div className={`intro-item i-${index + 1}`}>
                <img src={icon} alt={title} />
                <h2>{title}</h2>
                <p>{value}</p>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Wrapper>
  );
}

export default Intro;
